<template>
  <div class="page_layout">
    <div class="box" v-loading="loading">
      <div class="title" :class="{ titleEn: LOCALE == 'en' }">
        <div @click="handBack"><i class="el-icon-back"></i></div>
        <h1 @click="handBack">{{ $t("FillInformation") }}</h1>
      </div>

      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        :label-width="LOCALE == 'en' ? '160px' : '100px'"
        class="form_box"
      >
        <el-form-item :prop="'name_' + LOCALE">
          <span slot="label">{{ $t("companyName") }} </span>
          <el-input
            v-model="ruleForm['name_' + LOCALE]"
            class="long"
            :placeholder="$t('licenseofyourcompany')"
          ></el-input>
        </el-form-item>
        <div class="item_box">
          <el-form-item prop="country">
            <span slot="label">{{ $t("country") }}</span>
            <CountrySelect
              v-model="ruleForm.country"
              size="medium"
              :locale="LOCALE"
              class="short"
              :placeTitle="$t('countryPlaceholder')"
            ></CountrySelect>
          </el-form-item>
          <el-form-item prop="city">
            <span slot="label">{{ $t("city1") }} </span>
            <CitySelect
              v-model="ruleForm.city"
              :countryId="ruleForm.country"
              size="medium"
              :locale="LOCALE"
              class="short"
              :placeTitle="$t('cityPlaceholder')"
            ></CitySelect>
          </el-form-item>
        </div>
        <div class="item_box">
          <el-form-item prop="registered_capital">
            <span slot="label">{{ $t("registered") }}</span>
            <el-input
              v-model="ruleForm.registered_capital"
              class="short"
              type="number"
              :placeholder="$t('pleaseRegistered')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="found_year">
            <span slot="label">{{ $t("established") }}</span>
            <el-date-picker
              value-format="yyyy"
              v-model="ruleForm.found_year"
              :picker-options="p_op"
              type="year"
              :placeholder="$t('ChooseYear')"
              class="short"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="item_box">
          <el-form-item prop="scale">
            <span slot="label">{{ $t("company Size") }}</span>
            <el-select v-model="ruleForm.scale" class="short">
              <el-option
                v-for="(item, i) in staffsizeList"
                :value="item.value"
                :label="item.label_en | priorFormat(item.label_zh, LOCALE)"
                :key="i"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <span slot="label">{{ $t("company_net") }}</span>
            <el-input
              v-model="ruleForm.website"
              class="short"
              :placeholder="$t('pleaseEnterWebsite')"
            ></el-input>
          </el-form-item>
        </div>
        <div class="item_box">
          <el-form-item prop="contacts">
            <span slot="label">{{ $t("contact") }}</span>
            <el-input
              v-model="ruleForm.contacts"
              class="short"
              :placeholder="$t('pleaseConcat')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="telephone">
            <span slot="label">{{ $t("contactPhone") }}</span>
            <el-input
              v-model="ruleForm.telephone"
              class="short"
              :placeholder="$t('pleaseEnterConcat')"
            ></el-input>
          </el-form-item>
        </div>
        <div class="item_box">
          <el-form-item prop="email">
            <span slot="label">{{ $t("email") }}</span>
            <el-input
              v-model="ruleForm.email"
              :placeholder="$t('pleaseCompanyEmail')"
              class="short"
            ></el-input>
          </el-form-item>
          <el-form-item prop="fax">
            <span slot="label">{{ $t("fax") }}</span>
            <el-input
              v-model="ruleForm.fax"
              class="short"
              :placeholder="$t('pleaseEnterFax')"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item :v-for="'address_' + LOCALE">
          <span slot="label">{{ $t("companyAddress") }} </span>
          <el-input
            v-model="ruleForm['address_' + LOCALE]"
            class="long"
            :placeholder="$t('pleaseCompanyAddress')"
          ></el-input>
        </el-form-item>
        <el-form-item style="z-index: 200" :prop="'profile_' + LOCALE">
          <span slot="label">{{ $t("companyDesc") }}</span>
          <el-input
            v-model="ruleForm['profile_' + LOCALE]"
            type="textarea"
            class="long"
            :autosize="{ minRows: 10, maxRows: 10 }"
            maxlength="600"
            style="height: 220px"
            show-word-limit
            :placeholder="$t('pleaseentersomekey')"
          ></el-input>
        </el-form-item>
        <div class="fileUpload_box">
          <el-image
            class="img_show"
            v-if="ruleForm.url != ''"
            :src="ruleForm.url"
            :preview-src-list="[ruleForm.url]"
            fit="contain"
            :alt="$t('FillInformation')"
          ></el-image>

          <fileUpload
            style="float: right"
            @uploadCompleteOSS="_uploadCompleteOSS"
            :accept="fileUploadOptions.accept"
            :autoUpload="fileUploadOptions.autoUpload"
          >
            <el-button type="primary" icon="el-icon-upload" size="small">{{
              $t("uploadBusiness")
            }}</el-button>
          </fileUpload>
        </div>
        <div class="btn_box">
          <div class="btn_left" @click="handBack">{{ $t("cancel") }}</div>
          <div class="btn_right" @click="submitForm('ruleForm')">
            {{ $t("confirmsubmission") }}
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import CountrySelect from "@/baseComponents/CountrySelect";
import CitySelect from "@/baseComponents/CitySelect";
import datePicker from "@/baseComponents/datePicker";
import fileUpload from "@/baseComponents/fileUpload";
import { staffsizeList } from "~hns/utils/enumValue";
import { validateEmail, validateTelephone } from "~/baseUtils/validate";
import { getCityNameById } from "@/baseUtils/AddressConfig/index";
export default {
  metaInfo() {
    return {
      title: this.$t("FillInformation"),
    };
  },
  components: {
    CountrySelect,
    CitySelect,
    datePicker,
    fileUpload,
  },
  data() {
    var check_name_zh = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(" ")); //企业名称不能为空
      } else {
        return callback();
      }
    };
    var check_name_en = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(" ")); //企业名称不能为空
      } else {
        return callback();
      }
    };
    var check_country = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(" ")); //请选择国家
      } else {
        return callback();
      }
    };
    var check_city = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(" ")); //请选择城市
      } else {
        return callback();
      }
    };
    var check_registered_capital = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(" ")); //请填写注册资金
      } else {
        return callback();
      }
    };
    var check_found_year = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(" ")); //请选择企业注册年份
      } else {
        return callback();
      }
    };
    var check_scale = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(" ")); //请选公司规模
      } else {
        return callback();
      }
    };
    var check_contacts = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(" ")); //请填写联系人
      } else {
        return callback();
      }
    };
    var check_telephone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(" ")); //请填写联系人电话
      } else {
        return callback();
      }
    };
    var check_email = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(" ")); //请填写邮箱
      } else if (!validateEmail(value)) {
        return callback(new Error(" ")); //邮箱格式错误
      } else {
        return callback();
      }
    };
    var check_address_zh = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(" ")); //请填写企业地址
      } else {
        return callback();
      }
    };
    var check_address_en = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(" ")); //请填写企业地址
      } else {
        return callback();
      }
    };
    var check_profile_zh = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(" ")); //请填企业简介
      } else {
        return callback();
      }
    };
    var check_profile_en = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(" ")); //请填企业简介
      } else {
        return callback();
      }
    };

    return {
      p_op: {
        disabledDate(time) {
          const curDate1 = new Date().getTime();
          return time.getTime() > curDate1;
        },
      },
      staffsizeList: staffsizeList,
      fileUploadOptions: {
        accept: ".jpg,.jpeg,.bmp,.png",
        autoUpload: true,
      },
      loading: false,
      found_year: "",
      ruleForm: {
        company_id: "", //公司id
        name_zh: "", //公司中文名
        name_en: "",
        country: "", //国家id
        region_zh: "", //地区
        region_en: "",
        city: "",
        registered_capital: "", //注册资金
        found_year: "", //注册年份
        scale: "", //公司规模
        website: "", //公司官网,
        contacts: "", //联系人
        telephone: "",
        email: "",
        fax: "", //传真
        address_zh: "", //地址中文
        profile_zh: "", //企业简介
        logo: "",
        url: "",
        reason: "",
        parent_id: "",
        apply_from: 2,
        source: this.PJSource,
        sales_id: "2",
        status: "",
        is_show: "",
      },
      rules: {
        name_zh: [
          { validator: check_name_zh, trigger: "blur", required: true },
        ],
        name_en: [
          { validator: check_name_en, trigger: "blur", required: true },
        ],
        country: [
          { validator: check_country, trigger: "blur", required: true },
        ],
        city: [{ validator: check_city, trigger: "blur", required: true }],
        registered_capital: [
          {
            validator: check_registered_capital,
            trigger: "blur",
            required: true,
          },
        ],
        found_year: [
          { validator: check_found_year, trigger: "blur", required: true },
        ],
        scale: [{ validator: check_scale, trigger: "blur" }],
        contacts: [
          { validator: check_contacts, trigger: "blur", required: true },
        ],
        telephone: [
          { validator: check_telephone, trigger: "blur", required: true },
        ],
        email: [{ validator: check_email, trigger: "blur" }],
        address_zh: [
          { validator: check_address_zh, trigger: "blur", required: true },
        ],
        address_en: [
          { validator: check_address_en, trigger: "blur", required: true },
        ],
        profile_zh: [
          { validator: check_profile_zh, trigger: "blur", required: true },
        ],
        profile_zh: [
          { validator: check_profile_en, trigger: "blur", required: true },
        ],
      },
    };
  },
  async created() {
    this.ruleForm.company_id = this.$store.state.baseStore.userInfo.company_id;
    //获取公司信息 && 赋值
    let res = await this.$store.dispatch("API_company/getCompanyBrief", {
      company_id: this.ruleForm.company_id,
    });
    var msg = res.data;
    this.ruleForm.address_zh = msg.address_zh;
    this.ruleForm.contacts = msg.contacts;
    this.ruleForm.email = msg.email;
    this.ruleForm.fax = msg.fax;
    this.ruleForm.logo = msg.logo;

    try {
      this.ruleForm.found_year = msg.found_year.toString();
    } catch (error) {}

    this.ruleForm.registered_capital = msg.registered_capital;
    this.ruleForm.website = msg.website;
    this.ruleForm.telephone = msg.telephone;
    this.ruleForm.address_en = msg.address_en;
    this.ruleForm.city = msg.city;
    this.ruleForm.country = msg.country;
    this.ruleForm.is_show = msg.is_show;
    this.ruleForm.name_en = msg.name_en;
    this.ruleForm.name_zh = msg.name_zh;
    this.ruleForm.parent_id = msg.parent_id;
    this.ruleForm.reason = msg.reason;
    this.ruleForm.region_en = msg.region_en;
    this.ruleForm.region_zh = msg.region_zh;
    this.ruleForm.status = msg.status;
    this.ruleForm.source = msg.source;
    this.ruleForm.scale = msg.scale;
    this.ruleForm.profile_zh = msg.profile_zh;
    this.ruleForm.profile_en = msg.profile_en;

    //获取企业证书 getQualification
    let Qualification_params = {
      company_id: this.ruleForm.company_id,
      certificate_type: 0,
      nopage: 1,
      usage: 3,
      website: 2,
    };
    let result_vip = await this.$store.dispatch(
      "API_index/getQualification",
      Qualification_params
    );
    try {
      this.ruleForm.url = result_vip.data[0].url;
    } catch (err) {}
  },
  mounted() {},
  methods: {
    getCityNameById,
    handBack() {
      window.history.go(-1);
    },
    _uploadCompleteOSS(res) {
      this.ruleForm.url = res.url;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.upload_events();
        } else {
          return false;
        }
      });
    },
    async upload_events() {
      if (this.ruleForm.profile_zh == "") {
        this.$message.warning(this.$t("pleaseentersomekey"));
        return;
      }

      if (this.ruleForm.url == "" || !this.ruleForm.url) {
        this.$message.warning(this.$t("pleaseEnterBusiness"));
        return;
      }
      this.loading = true;
      // 保存 提交公司营业执照
      let company_qualificationUpload = await this.$store.dispatch(
        "API_index/company_qualificationUpload",
        {
          company_id: this.ruleForm.company_id,
          url: this.ruleForm.url,
          certificate_type: 0,
        }
      );
      //  保存公司信息

      this.ruleForm.region_zh = this.getCityNameById(this.ruleForm.city, "zh");
      let result_save = await this.$store.dispatch(
        "API_index/saveCompanyMsg",
        this.ruleForm
      );

      //  注册
      this.ruleForm.source = this.PJSource;
      let result_vip = await this.$store.dispatch(
        "API_index/companyVipApply",
        this.ruleForm
      );
      this.loading = false;
      if (result_vip.success) {
        this.$message.success(this.$t("submitSuccess"));
        this.$router.push({ path: "/memberShip" });
      } else {
        this.$message.warning(result_vip.message);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.long {
  width: 682px;
}
.short {
  width: 270px;
}

.read_require {
  padding-top: 9px;
  display: flex;
  padding-right: 10px;
  width: 95px;
  text-align: left;
  padding-left: 21px;
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  i {
    background: #ff4949;
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    margin-top: 2px;
  }
}
.page_layout {
  .box {
    width: 1200px;
    box-sizing: border-box;
    margin: auto;
    .title {
      margin: auto;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      div {
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        border: 1px solid #7d8695;
        border-radius: 10px;
      }
      h1 {
        padding-left: 16px;
        width: 142px;
        height: 29px;
        font-size: 21px;
        font-weight: 400;
        color: #1f292e;
        line-height: 29px;
      }
    }
    .titleEn {
      h1 {
        width: 210px;
      }
    }
  }
  .form_box {
    width: 786px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .item_box {
      display: flex;
    }
    .el-form-item {
      margin-bottom: 20px;
    }
    .fileUpload_box {
      position: relative;
      .img_show {
        width: 80px;
        height: 65px;
        position: absolute;
        top: -6px;
        left: 100px;
      }
    }
    .btn_box {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      .btn_left {
        cursor: pointer;
        width: 336px;
        height: 42px;
        background: #f2f2f3;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 800;
        color: #c0c4cc;
        line-height: 42px;
        text-align: center;
        margin-right: 30px;
      }
      .btn_right {
        cursor: pointer;
        width: 336px;
        height: 42px;
        background: linear-gradient(90deg, #ff9966 0%, #ff5e62 100%);
        border-radius: 4px;
        font-size: 16px;
        font-weight: 800;
        color: #ffffff;
        line-height: 42px;
        text-align: center;
      }
    }
  }
}
</style>